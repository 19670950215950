import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'


//export function Pointer(props) {
  // This reference will give us direct access to the mesh
  //const mesh = useRef();
  // Set up state for the hovered and active state

  // Subscribe this component to the render-loop, rotate the mesh every frame
 
  // Return view, these are regular three.js elements expressed in JSX
  //return (
    //<mesh
      //{...props}
      //ref={mesh}
      //scale={1}
    //>
      //<boxBufferGeometry args={[0.70, 0.40, 0.42]} />
      //<meshPhongMaterial color={"#D3D3FF "} opacity={0.5} transparent/>
    //</mesh>
  //);
//}

export function Pointer(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/arrow.gltf')
  //const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Arrow">
        <group name="CameraPreset" position={[0, 0.8, 0.02]} />
        <group name="LightPreset" position={[0, 0.8, 0.02]} />
        <mesh name="Box1" geometry={nodes.Box1.geometry} material={materials.Standard} position={[0, 0.31, 0.02]} scale={[0.15, 0.15, 0.3]} material-color={"#e75a29"}/>
      </group>
    </group>
  )
}

