import React, { useRef, useState } from 'react';

export function Floor(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef();
    // Set up state for the hovered and active state

    const [active, setActive] = useState(false);
    
    // Subscribe this component to the render-loop, rotate the mesh every frame
   
    // Return view, these are regular three.js elements expressed in JSX
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={1}
        onClick={(event) => setActive(!active)}
      >
        <boxBufferGeometry args={[props.x, props.y, props.z]} />
        <meshStandardMaterial color={'#A47528'}/>
      </mesh>
    );
  }